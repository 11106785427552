import styled from 'styled-components/macro';
import mapNorway from './map-norway.png';
import linkNewTab from '../ui/assets/legacyIcons/LinkNewTab.svg';

export const Container = styled.div`
  color: #fff;
  font-family: 'Overpass', sans-serif;
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-image: url(${mapNorway});
  background-size: cover;

  pointer-events: none;
  z-index: -1;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 40px 0;
`;

export const Logo = styled.img`
  height: 108px;
`;

export const Content = styled.div`
  max-width: 556px;
  margin: 0 auto;
`;

export const Card = styled.div`
  position: relative;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.25);
`;

const CardItem = styled.div`
  padding: 40px;
`;

export const CardContent = styled(CardItem)`
  background: #292e39;
  padding-bottom: 22px;
`;

export const CardFooter = styled(CardItem)`
  background: #202530;
  padding-top: 22px;
  padding-bottom: 22px;
`;

interface BaseTextProps {
  readonly fontSize?: string;
  readonly lineHeight?: string | number;
}

const BaseText = styled.p<BaseTextProps>`
  margin: 0;
  padding: 0;

  font-size: ${({ fontSize }) => fontSize || '1.6rem'};
  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
`;

export const Heading = styled(BaseText)`
  margin-bottom: 1.6rem;

  /* Can't use font-variant-caps here because it
  doesn't work on IE */
  text-transform: uppercase;
  font-size: 1.6rem;
`;

export const BodyText = styled(BaseText)`
  color: #aaa;
  font-size: 1.6rem;
  line-height: 1.65;

  & + & {
    margin-top: 1.6rem;
  }
`;

export const BodyTextNoBreak = styled(BodyText)`
  word-break: keep-all;
  white-space: nowrap;
`;

export const Ul = styled.ul`
  margin: 1.6rem 0;
  padding: 0 3.2rem;
`;

export const Li = styled.li``;

export const Link = styled.a`
  text-decoration: none;
  color: #52c3f9;
  line-height: 1.6;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  &::after {
    content: '';
    position: relative;
    display: inline-block;

    background-image: url(${linkNewTab});
    background-repeat: no-repeat;

    top: 0.2rem;
    height: 1.3rem;
    width: 1.3rem;
    margin: 0 0.3rem 0 0.4rem;
  }
`;
